"use client";

import * as React from "react";
// import Box from "@mui/material/Box";
import Image from "next/image";
import Grid from "@mui/material/Grid";

import { Box, Button, Typography, useTheme } from "@mui/material";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import { useWithdrawStore } from "@/stores/withdrawStore";
import { useSnackbar } from "notistack";
import { useAppModalStore } from "@/stores/appModalStore";
import numeral from "numeral";
import { LoadingButton } from "@mui/lab";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";
import useMe from "@/hooks/fetchers/useMe";
import { Stack } from "@mui/system";
import { useConfigStore } from "@/stores/configStore";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

type Inputs = {
  amount: number;
};

const schema = yup.object().shape({
  amount: yup
    .number()
    .required("กรุณากรอกจำนวนเงิน")
});

export default function WithdrawForm() {
  const theme = useTheme();
  const [user] = useAuthStore((state) => [state.user], shallow);
  const [withdraw] = useWithdrawStore((state) => [state.withdraw], shallow);
  const [loading, setLoading] = React.useState(false);
  const [setOpen, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const { refresh: refreshPro, data: promotion } = usePromotionHistory();
  const { refresh: refreshMe } = useMe();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    reset
  } = useForm<Inputs>({ resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoading(true);
    const amount = numeral(data.amount).value() || 0;

    if ((user?.balance || 0) < data.amount) {
      enqueueSnackbar("ยอดเงินคงเหลือไม่เพียงพอ กรุณาลองใหม่อีกครั้ง.", {
        variant: "error",
      });
      setLoading(false);
      return
    }

    await withdraw(amount)
      .then((res: any) => {
        if (res?.errMessage) {
          enqueueSnackbar(res.errMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          setLoading(false);
        } else {
          enqueueSnackbar("สำเร็จ", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          refreshPro();
          refreshMe();
          setOpen(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
        setLoading(false);
      });
  };

  React.useEffect(() => {
    reset({
      amount: parseInt(numeral(user?.balance).format('0'), 10)
    });
  }, [])

  return (
    <Box display="flex" alignItems="center" justifyContent="center" p={2} borderRadius={2}>
      {/* Coin Icon */}
      <Box mr={2}>
        <Image src="/assets/icons/coin.png" width={50} height={50} alt="coin icon" />
      </Box>


      {/* Balance and Withdraw Button */}

      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Typography variant="body1" color="textPrimary" mr={2}>
            จำนวนเงินทั้งหมด
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between" // This will push the button to the right
            bgcolor="#d8dee9"
            borderRadius={1}
            p={1}
          >
            {/* Display Balance */}
            <Typography variant="h6" color="#4c566a">
              {user?.balance}
            </Typography>

            {/* Withdraw Button */}
            <Button
              variant="contained"
              sx={{ bgcolor: "#4c566a", color: "#eceff4", textTransform: "none", borderRadius: 1 }}
            >
              แจ้งถอน
            </Button>
          </Box>
        </Grid>


      </Grid>

    </Box>
  );
}
