"use client";

import * as React from "react";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material";
import Image from "next/image";
import { useMediaQuery } from "@mui/material";
import gamelist from "@/hooks/fetchers/useProvider";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import { useAuthModalStore } from "@/stores/authModalStore";
import { Box, Grid, CircularProgress, Typography, IconButton } from "@mui/material";
import { scroller } from "react-scroll";
import { useRouter, useSearchParams } from "next/navigation";
import { isMobile } from "react-device-detect";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import useLaunch from "@/hooks/useLaunch";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MainMenu from "./MainMenu";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Casino, SportsSoccer, CardGiftcard, BarChart, LocalActivity, MonetizationOn } from "@mui/icons-material";
import { margin, spacing } from "@mui/system";
import { useAppModalStore } from "@/stores/appModalStore";
import "./style.css";

const eventCardSliderSettings = {
  dots: true,
  infinite: true,
  padding: 5,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      padding: "5px",
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const sliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 3,
  display: 'flex',
  alignContent: 'start',
  justifyContent: 'start',
  alignItems: 'start',
  initialSlide: 0,
  arrows: true,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 5,
        rows: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        rows: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        rows: 1,
      },
    },
  ],
};

const sliderSettingsLottery = {
  swipe: false,
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 2,
  display: 'flex',
  alignContent: 'start',
  justifyContent: 'start',
  alignItems: 'start',
  initialSlide: 0,
  arrows: true,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 5,
        rows: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 5,
        rows: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        rows: 1,
      },
    },
  ],
};

const sliderSettingsSport = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 2,
  display: 'flex',
  alignContent: 'start',
  justifyContent: 'start',
  alignItems: 'start',
  initialSlide: 0,
  arrows: true,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 5,
        rows: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 5,
        rows: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        rows: 1,
      },
    },
  ],
};

const CategoryBox = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(	58, 55, 79,0.8)",
  borderRadius: "20px",
  textAlign: "center",
  padding: "3px",
  paddingTop: "5px",
  fontWeight: "bold",
  fontSize: "20px",
  color: "white",
  // marginRight: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "background-color 0.3s ease-in-out",

}));

type Game = {
  category: string;
  name: string;
  code: string;
  status: boolean;
  display: boolean;
  image: string;
  image_newlobby: string;
  is_lobby: boolean;
  games_lobby: any[];
};

type GroupedArrayItem = {
  category: string;
  data: Game[];
};

const CATEGORY: { [key: string]: string } = {
  slot: "สล็อต",
  // graph: "กราฟ",
  casino: "คาสิโนสด",
  // card: "เกมไพ่",
  lottery: "หวย",
  sport: "กีฬา",
};

const CATEGORY_ICONS: { [key: string]: JSX.Element } = {
  slot: <LocalActivity fontSize="medium" sx={{ color: "#FFDE8B" }} />,
  // graph: <BarChart fontSize="medium" sx={{ color: "#FFDE8B" }} />,
  casino: <Casino fontSize="medium" sx={{ color: "#FFDE8B" }} />,
  // card: <CardGiftcard fontSize="medium" sx={{ color: "#FFDE8B" }} />,
  lottery: <MonetizationOn fontSize="medium" sx={{ color: "#FFDE8B" }} />,
  sport: <SportsSoccer fontSize="medium" sx={{ color: "#FFDE8B" }} />,
};


// const ItemImage = styled(Image)(({ theme }) => ({
//   transition: "all 0.12s ease-in-out",
//   "&:hover": {
//     transform: "scale(1.08)",
//     cursor: "pointer",
//   },
// }));

const ItemWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  "&:hover .hoverOverlay": {
    opacity: 1,
    visibility: "visible",
  },
}));

const ItemImage = styled(Image)(({ theme }) => ({
  transition: "all 0.12s ease-in-out",
  "&:hover": {
    transform: "scale(1.08)",
    cursor: "pointer",
  },
}));

const HoverOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0,
  borderRadius: 20,
  visibility: "hidden",
  transition: "opacity 0.3s, visibility 0.3s",
  zIndex: 2,
}));

const PlayButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  color: "white",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: theme.palette.warning.dark,
  },
}));



export default function ProvidersList() {
  const eventSliderRef = React.useRef<Slider>(null);

  const slickPrevEvent = () => {
    if (eventSliderRef.current) {
      eventSliderRef.current.slickPrev();
    }
  };

  const slickNextEvent = () => {
    if (eventSliderRef.current) {
      eventSliderRef.current.slickNext();
    }
  };
  const theme = useTheme();
  const { launchGame } = useLaunch();
  const router = useRouter();
  const searchParams = useSearchParams();
  const category = searchParams.get("category");
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  let width: number, height: number;

  if (isMdUp) {
    width = 1667 / 8;
    height = 2417 / 8;
  } else if (isSmUp) {
    width = 1667 / 10;
    height = 2417 / 10;
  } else {
    width = 1667 / 14;
    height = 2417 / 14;
  }

  const {
    data: promotion,
    isLoading: isPromotionListLoading,
    isActive,
  } = usePromotionHistory();

  const activePromotionPlatform =
    promotion?.promotion?.platform?.split(",") || [];

  const { data: gameList, isLoading: isGameListLoading } = gamelist();
  const sortedKeys = Object.keys(CATEGORY);

  const groupedData = gameList
    ? gameList.reduce((result: any, item: any) => {
      const category = item.category;
      if (!result[category]) {
        result[category] = [];
      }
      result[category].push(item);
      return result;
    }, {})
    : {};

  const groupedArray: GroupedArrayItem[] = sortedKeys.reduce(
    (sortedArray: GroupedArrayItem[], key: string) => {
      let data = groupedData[key];
      if (data) {
        data = data.sort((a: Game, b: Game) => {
          const aIsInActivePromotionPlatform = activePromotionPlatform.includes(
            a.code
          );
          const bIsInActivePromotionPlatform = activePromotionPlatform.includes(
            b.code
          );
          if (aIsInActivePromotionPlatform && !bIsInActivePromotionPlatform) {
            return -1;
          }
          if (!aIsInActivePromotionPlatform && bIsInActivePromotionPlatform) {
            return 1;
          }
          return 0;
        });
        sortedArray.push({ category: key, data: data as Game[] });
      }
      return sortedArray;
    },
    []
  );

  React.useEffect(() => {
    if (category && groupedArray) {
      scroller.scrollTo(`category-${category}`, {
        duration: 1000,
        delay: 200,
        smooth: "easeInOutQuart",
        offset: -100,
      });
    }
  }, [category, groupedArray]);

  const [isLoggedIn] = useAuthStore((state) => [state.user!], shallow);

  const handleRightMenuClick = () => {
    setOpenLoginModal(true);
  };

  const handleProviderClick = (
    gameId: string,
    gameCode: string,
    category: string,
    platformCode: string,
    isIframe: boolean = true
  ) => {
    if (!isLoggedIn) return;

    if (isActive && ![...activePromotionPlatform].includes(gameCode)) return;

    if (["casino", "lottery", "sport"].includes(category)) {
      launchGame(gameId, isMobile, isIframe);
      return;
    }

    router.push(`lobbies/${gameCode}`);
  };

  const [setOpenAppModal, setAppDataModal] = useAppModalStore(
    (state) => [state.setOpen, state.setData],
    shallow
  );
  const [setOpen, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);

  const [setOpenLoginModal] = useAuthModalStore(
    (state) => [state.setOpenLoginModal],
    shallow
  );
  const handleItemClick = (key: string) => {
    if (
      key == "wheel" || key == "mysteryBox") {
      if (!isLoggedIn) {
        setOpenLoginModal(true);
      } else {
        setOpen(true);
        setData(key);
      }


    }
  };
  const MAX_ROWS = isMobileDevice ? 1 : 1;
  const ITEMS_PER_ROW = isMobileDevice ? 3 : 7;
  const sliderRef = React.useRef<Slider>(null);

  const maxItems = MAX_ROWS * ITEMS_PER_ROW;

  const [visibleIndices, setVisibleIndices] = React.useState<{ [key: string]: number }>({});
  const sliderRefs = React.useRef<{ [key: string]: Slider | null }>({});

  const slickNext = (category: string, totalItems: number) => {
    if (sliderRefs.current[category]) {
      sliderRefs.current[category]?.slickNext();
    }
    setVisibleIndices((prev) => ({
      ...prev,
      [category]: Math.min((prev[category] || 0) + ITEMS_PER_ROW, totalItems - ITEMS_PER_ROW),
    }));
  };

  const slickPrev = (category: string) => {
    if (sliderRefs.current[category]) {
      sliderRefs.current[category]?.slickPrev();
    }
    setVisibleIndices((prev) => ({
      ...prev,
      [category]: Math.max((prev[category] || 0) - ITEMS_PER_ROW, 0),
    }));
  };

  if (isGameListLoading) {
    return (
      <Grid sx={{ textAlign: "center" }}>
        <CircularProgress />
      </Grid>
    );
  } else {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid item xs={12} md={12} sx={{ pt: 2, pb: 0 }}>
          <MainMenu />
        </Grid>

        <Grid container spacing={0} xs={12} sx={{ marginTop: "15px" }}>
          <Grid item xs={5} md={2} >
            <CategoryBox>
              <Casino fontSize="medium" sx={{ color: "#FFDE8B" }} />
              &nbsp;
              <Typography>กิจกรรม</Typography>
            </CategoryBox>
          </Grid>
          <Grid item xs={7} md={10} sx={{ display: 'flex', justifyContent: 'end' }}>
            <IconButton onClick={slickPrevEvent}>
              <ArrowBackIos />
            </IconButton>
            <IconButton onClick={slickNextEvent}>
              <ArrowForwardIos />
            </IconButton>
          </Grid>

          <Grid item xs={12} spacing={2} sx={{ marginTop: "15px", justifyContent: "center", alignItems: "center" }}>
            <Slider ref={eventSliderRef} {...eventCardSliderSettings}>
              <Card
                sx={{
                  padding: "10px",
                  backgroundImage: `url('/assets/game_m9/event/${isMobileDevice ? 'box_mo' : 'box'}.webp')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "16px",
                  width: "100%",
                  maxWidth: "570px",
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginRight: "30px",
                }}
              >
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', padding: '10px 0', marginTop: isMobileDevice ? '60%' : '44%', }}>
                  <Button
                    variant="contained"
                    sx={{
                      background: "linear-gradient(150deg, #FFDE8B, #FFB762, #FF9416  )",                      color: "black",
                      borderRadius: "20px",
                      padding: "5px 20px",
                      textTransform: "none",
                    }}
                    onClick={() => handleItemClick("mysteryBox")}
                  >
                    กล่องสุ่ม
                  </Button>
                </Box>
              </Card>

              <Card
                sx={{
                  padding: "10px",
                  backgroundImage: `url('/assets/game_m9/event/${isMobileDevice ? 'wheel_mo' : 'wheel'}.webp')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "16px",
                  width: "100%",
                  maxWidth: "570px",
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginRight: "30px",
                }}
              >
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', padding: '10px 0', marginTop: isMobileDevice ? '60%' : '44%', }}>
                  <Button
                    variant="contained"
                    sx={{
                      background: "linear-gradient(150deg, #FFDE8B, #FFB762, #FF9416  )",                      color: "black",
                      borderRadius: "20px",
                      padding: "5px 20px",
                      textTransform: "none",
                    }}
                    onClick={() => handleItemClick("wheel")}
                  >
                    กงล้อสุ่ม
                  </Button>
                </Box>
              </Card>
            </Slider>
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          {groupedArray.map((item) => {
            console.log(item.category === "lottery")

            const totalItems = item.data.length;
            const sliderConfig = {
              ...(
                item.category === "lottery"
                  ? sliderSettingsLottery
                  // : item.category === "sport"
                  //   ? sliderSettingsSport
                    : sliderSettings
              ),
              infinite: totalItems > 4, // Disable infinite scrolling if less than 6 items
              arrows: false, // Hide default navigation arrows
              draggable: totalItems > 6, // Disable dragging if less than 6 items
            };

            return (
              <Grid
                id={`category-${item.category}`}
                key={`category-${item.category}`}
                item
                xs={12}
                sx={{ pt: 2, pb: 0 }}
              >
                <Grid container spacing={2} sx={{ pt: 0, pb: 2 }}>
                  <Grid item xs={5} md={2}>
                    <CategoryBox>
                      {CATEGORY_ICONS[item.category] || <Casino fontSize="medium" />}
                      &nbsp;
                      <Typography>{CATEGORY?.[item.category] || "~"}</Typography>
                    </CategoryBox>
                  </Grid>
                  <Grid item xs={7} md={10} sx={{ display: "flex", justifyContent: "end", alignItems: 'center' }}>
                    {/* Custom navigation arrows */}
                    <IconButton
                      onClick={() => slickPrev(item.category)}
                      disabled={totalItems <= (isMobileDevice ? 3 : 6)}  // Disable if totalItems <= 6
                    >
                      <ArrowBackIosIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => slickNext(item.category, totalItems)}
                      disabled={totalItems <= (isMobileDevice ? 3 : 6)} // Disable if totalItems <= 6
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Grid>
                </Grid>

                <Slider
                  ref={(el: Slider | null) => {
                    sliderRefs.current[item.category] = el;
                  }}
                  {...sliderConfig}

                >
                  {item.data.map((game) => (
                    <div key={game.code}>
                      <ItemWrapper>
                        <Box
                          style={{
                            textDecoration: "none",
                            color: theme.palette.primary.main,
                            textAlign: "center",
                            margin: "0 8px",
                          }}
                          onClick={() => {
                            if (!isLoggedIn) {
                              handleRightMenuClick();
                              return;
                            }
                            handleProviderClick(
                              game?.games_lobby?.[0]?.id || "",
                              game?.code || "",
                              game?.category || "",
                              game?.games_lobby?.[0]?.code || "",
                              game?.games_lobby?.[0]?.is_iframe ?? true
                            );
                          }}
                        >
                          <ItemImage
                            placeholder={"blur"}
                            blurDataURL="/assets/icons/category-casino.png"
                            src={
                              `/assets/game_m9/${game.category}/${game.name}.webp` ||
                              "/assets/icons/category-casino.png"
                            }
                            width={width}
                            height={height}
                            alt={game.name}
                            style={{
                              width: "100%",
                              height: "auto",
                              filter:
                                isLoggedIn &&
                                  isActive &&
                                  !activePromotionPlatform.includes(game?.code || "")
                                  ? "grayscale(100%)"
                                  : "grayscale(0%)",
                            }}
                          />

                          {/* Hover Overlay */}
                          <HoverOverlay className="hoverOverlay">
                            <Typography sx={{ color: "white", fontWeight: "bold" }}>
                              {game.name}
                            </Typography>
                            <PlayButton>Play</PlayButton>
                          </HoverOverlay>
                        </Box>
                      </ItemWrapper>
                    </div>
                  ))}
                </Slider>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  }
}

