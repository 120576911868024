"use client";

import React, { useState, useEffect, useRef } from "react";

import { useUserProfile } from "@/hooks";
import { styled } from "@mui/material/styles";
import { useAuthStore } from "@/stores/authStore";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Alert, AlertTitle, Divider, Stack, Typography, IconButton, Button, alpha } from "@mui/material";
import { useAppModalStore } from "@/stores/appModalStore";
import { Icon } from "@iconify/react";
import { useRouter } from "next/navigation";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { shallow } from "zustand/shallow";
import numeral from "numeral";
import { useConfigStore } from "@/stores/configStore";

type Menu = {
  key: string;
  label: string;
  icon?: string;
};

const menus: Menu[] = [
  { key: "historyAll", label: "ประวัติ", icon: "mdi:history" },
  { key: "affiliate", label: "แนะนำเพื่อน", icon: "mdi:invite" },
  { key: "refund", label: "ยอดเสีย", icon: "gridicons:refund" },
  { key: "cashbackTurnover", label: "คืนยอดเล่น", icon: "gridicons:money" },
  { key: "cashbackWinlose", label: "คืนยอดเสีย", icon: "gridicons:refund" },
  { key: "promotion", label: "โปรโมชั่น", icon: "ph:gift-fill" },
  { key: "coupon", label: "คูปอง", icon: "mdi:coupon" },
  { key: "wheel", label: "กงล้อ", icon: "lucide:ferris-wheel" },
  { key: "mysteryBox", label: "กล่องสุ่ม", icon: "ph:archive-bold" },
];

const Item = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1),
  textAlign: "center",
  transition: "all 0.12s ease-in-out",
  "& #icon": {
    color: theme.palette.primary.main,
    ...(theme.name === "preset3" || theme.name === "preset6" || theme.name === "preset5") && {
      color: "white",
    },
  },
  color: theme.palette.text.secondary,
  "&:hover": {
    transform: "scale(1)",
    cursor: "pointer",
    "& #icon-container": {
      background: theme.palette.primary.main,
    },
    "& #icon": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    "& #label": {
      fontWeight: 600,
      color: theme.palette.getContrastText(theme.palette.primary.main),
      ...(theme.name === "preset5" && {
        color: "#86c01a",
      }),
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  boxShadow: theme.shadows[8],
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 50,
  width: "100%", // Use dynamic width
  borderRadius: 30,
  transition: "all 0.12s ease-in-out",
}));

const MenuWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  overflowX: "auto",
  scrollSnapType: "x mandatory",
  position: "relative",
  scrollBehavior: "smooth",
  "& > *": {
    scrollSnapAlign: "center",
  },
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const BoxProfile = styled(Box)(({ theme }) => ({
  flexDirection: "row",
  marginBottom: theme.spacing(1),
}));
const NavigationButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 1,
  backgroundColor: alpha(theme.palette.background.default, 0.7),
  "&:hover": {
    backgroundColor: theme.palette.background.default,
  },
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

export default function MainMenu() {
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const menuRef = useRef<HTMLDivElement>(null);
  const slidesToShow = isMobile ? 3 : 6;
  const {
    isLoading,
    currentBalance,
    promotionHistory,
    handleRemovePro,
    isPromotionHistoryLoading,
    isBonus,
  } = useUserProfile();
  
  const [config] = useConfigStore((state) => [state.config], shallow);
  const [user] = useAuthStore((state) => [state.user], shallow); // Get user from authStore
  const [setOpen, setData] = useAppModalStore((state) => [state.setOpen, state.setData]);
  const [cashback, setCashback] = useState<number>(0);

  useEffect(() => {
    if (isMobile && menuRef.current) {
      const depositIndex = menus.findIndex((menu) => menu.key === "deposit");
      if (depositIndex !== -1) {
        const itemWidth = 85; // Width of each menu item
        const offset =
          depositIndex * itemWidth - menuRef.current.clientWidth / 2 + itemWidth / 2;
        menuRef.current.scrollTo({ left: offset, behavior: "smooth" });
      }
    }
  }, [isMobile]);

  const handleItemClick = (key: string) => {
    if (key === "removePro") {
      handleRemovePro(); // Call the handleRemovePro function
    } else if (key === "mystery-box") {
      router.push("/mystery-box");
    } else {
      setOpen(true);
      setData(key);
    }
  };

  const scrollMenu = (direction: "left" | "right") => {
    if (menuRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = menuRef.current;
      const scrollAmount = clientWidth / 3; // Scroll by one-third of the container width
      let newScrollPosition = 
        direction === "left" ? scrollLeft - scrollAmount : scrollLeft + scrollAmount;

      if (newScrollPosition < 0) {
        newScrollPosition = scrollWidth - clientWidth;
      } else if (newScrollPosition + clientWidth >= scrollWidth) {
        newScrollPosition = 0;
      }
      menuRef.current.scrollTo({ left: newScrollPosition, behavior: "smooth" });
    }
  };

  const filteredMenus = config?.cashback_type && config?.cashback_type === 'custom'
  ? menus.filter(
    (menu) => !["refund"].includes(menu.key)
  )
  : menus.filter(
    (menu) => !["cashbackTurnover", "cashbackWinlose"].includes(menu.key)
  )

  return (
    <Stack spacing={1} sx={{marginTop:"0px",marginLeft:"0%",marginRight:"0%"}}>
      <Box position="relative" 
           paddingLeft={3} paddingRight={3} 
           sx={{backgroundColor: "rgba(6,17,20)",borderRadius:'15px'}}>
        {user && (
          <NavigationButton onClick={() => scrollMenu("left")} sx={{ left: -10 }}>
            <Icon icon="ic:round-chevron-left" />
          </NavigationButton>
        )}
        <MenuWrapper ref={menuRef}>
          {user && filteredMenus.map((menu) => ( 
            <Box
              key={menu.key}
              onClick={() => handleItemClick(menu.key)}
              sx={{ flex: `0 0 ${100 / slidesToShow}%`, flexShrink: 0 }}> {/* Dynamic width */}
              <Item>
                <StyledBox id="icon-container">
                  <Icon id="icon" icon={menu.icon || ""} width={20} height={20} /> &nbsp;
                  <Typography
                    id="label"
                    fontWeight="400"
                    fontSize="12px"
                    variant="button">
                    {menu.label}
                  </Typography>
                </StyledBox>
              </Item>
            </Box>
          ))}
        </MenuWrapper>
        {user && (
          <NavigationButton onClick={() => scrollMenu("right")} sx={{ right: -10 }}>
            <Icon icon="ic:round-chevron-right" />
          </NavigationButton>
        )}
      </Box>
    </Stack>
  );
}

